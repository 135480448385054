import DownloadIcon from "@mui/icons-material/Download";
import React, { memo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { downloadAndSaveFile, safe } from "../../../util";
import { downloadInvoice } from "../../../service/omvApi";

export const DownloadInvoicesButton = memo(
  function DownloadInvoicesButton(props) {
    const { params, label } = props;
    const { texts } = useSelector((state) => state.language);
    const [busy, setBusy] = useState(false);

    const onDownloadInvoice = (id) =>
      safe(async () => {
        setBusy(true);
        const downloadInfo = {
          invoiceNumber: id,
          format: "PDF",
        };
        const blob = await downloadInvoice(downloadInfo);
        downloadAndSaveFile(
          blob,
          "orders_" + downloadInfo?.invoiceNumber,
          "PDF",
        );
      }).finally(() => {
        setBusy(false);
      });

    return (
      <LoadingButton
        variant="action"
        onClick={() => onDownloadInvoice(params.row.invoiceNumber)}
        startIcon={<DownloadIcon />}
        disabled={!params.row.invoicePdfAvailable}
        loading={busy}
        loadingIndicator={texts.common.downloading}
      >
        {label}
      </LoadingButton>
    );
  },
);
